/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect, useState } from 'react';
import { FaFacebookF } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useGoogleLogin } from '@react-oauth/google';
import { useMutation } from '@tanstack/react-query';
// eslint-disable-next-line import/order
import {
  ACCESS_TOKEN_EXPIRATION_TIME_LOCAL_STORAGE_KEY,
  ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  ACCESS_TOKEN_LOGIN_EXPIRE_MINUTES_LOCAL_STORAGE_KEY,
  ACCESS_TOKEN_LOGIN_TIME_LOCAL_STORAGE_KEY,
  REFERRAL_CODE_LOCAL_STORAGE_KEY,
  routes,
  SUB_ID_LOCAL_STORAGE_KEY,
  TOAST_CONFIG,
  USER_DATA_LOCAL_STORAGE_KEY,
} from 'constant';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import { Button, FormHeading, InputBox, TextTitleLogo, TitleLogo } from 'components';
import PageTitle from 'components/PageTitle';
import MobileRestrictionModal from 'components/TopNavigationBar/MobileRestrictionModal';
import { googleLoginApi, register, socialLogin } from 'api/auth';
import { catchBlock } from 'utils';
import Google from '../../../assets/google-image.png';
import { SignUpInitialValues, SignUpValidation } from './Constant';

import './style.css';

const RegisterPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [mobileRestrictionModalClass, setMobileRestrictionModalClass] = useState('d-none');
  const [initVals, setInitVals] = useState(SignUpInitialValues);
  useEffect(() => {
    if (search) {
      const query = new URLSearchParams(search);
      if (query.get('email') !== null) setInitVals({ ...initVals, ...{ email: query.get('email') } });
      if (query.get('PFemail') !== null)
        setInitVals({ ...initVals, ...{ prefilled: true, email: query.get('PFemail') } });
      if (query.get('cd') === '65bb76009a551920409b8733')
        toast.success('CONGRATULATIONS! Your referral code approved, please register to get benefits.', TOAST_CONFIG);
      if (query.get('er') === '65bb76009a551920409b8733')
        toast.error('SORRY! Your referral code is incorrect, please check.', TOAST_CONFIG);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const { mutate: registerPostMutate, isLoading } = useMutation(register, {
    onSuccess: (data) => {
      toast.success(data.message, TOAST_CONFIG);
      const query = new URLSearchParams(search);
      if (query.get('cd') === '65bb76009a551920409b8733') localStorage.removeItem(REFERRAL_CODE_LOCAL_STORAGE_KEY);
      if (query.get('email') !== null) navigate({ pathname: routes.login, search });
      else navigate({ pathname: routes.login });
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const RegisterPost = useCallback(
    (values) => {
      const query = new URLSearchParams(search);
      const referralCode = localStorage.getItem(REFERRAL_CODE_LOCAL_STORAGE_KEY);
      const requestValues = {
        name: values.name,
        email: values.email,
        password: values.password,
      };
      if (query.get('cd') === '65bb76009a551920409b8733') requestValues.referralCode = referralCode;
      registerPostMutate(requestValues);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [registerPostMutate],
  );

  const Login = () => {
    navigate({ pathname: routes.login, search });
  };

  const { mutate: socialLoginPostMutate, isLoading: socialIsLoading } = useMutation(socialLogin, {
    onSuccess: async (data) => {
      toast.success(data.message, TOAST_CONFIG);
      const sid = localStorage.getItem(SUB_ID_LOCAL_STORAGE_KEY);
      const query = new URLSearchParams(search);
      const referralCode = localStorage.getItem(REFERRAL_CODE_LOCAL_STORAGE_KEY);
      const userObj = {
        name: `${data.data.user.first_name} ${data.data.user.last_name}`,
        email: data.data.user.email,
        is_free_user: data.data.user.is_free_user,
        is_sub_user: data.data.user.is_sub_user,
      };
      if (query.get('cd') === '65bb76009a551920409b8733') userObj.referralCode = referralCode;
      if (sid) {
        await addSubscriptionMutation.mutate(
          { sid },
          {
            onSuccess: (subData) => {
              userObj.is_sub_user = subData.is_sub_user;
              localStorage.removeItem(SUB_ID_LOCAL_STORAGE_KEY);
              if (query.get('cd') === '65bb76009a551920409b8733')
                localStorage.removeItem(REFERRAL_CODE_LOCAL_STORAGE_KEY);
              // navigate(routes.dashBoard);
            },
            onError: (error) => {
              catchBlock(error);
              localStorage.removeItem(SUB_ID_LOCAL_STORAGE_KEY);
              if (query.get('cd') === '65bb76009a551920409b8733')
                localStorage.removeItem(REFERRAL_CODE_LOCAL_STORAGE_KEY);
              // navigate(routes.dashBoard);
            },
          },
        );
      }
      localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, data.data.token);
      localStorage.setItem(USER_DATA_LOCAL_STORAGE_KEY, JSON.stringify(userObj));
      localStorage.setItem(ACCESS_TOKEN_LOGIN_TIME_LOCAL_STORAGE_KEY, moment());
      localStorage.setItem(
        ACCESS_TOKEN_EXPIRATION_TIME_LOCAL_STORAGE_KEY,
        moment().add(ACCESS_TOKEN_LOGIN_EXPIRE_MINUTES_LOCAL_STORAGE_KEY, 'minutes'),
      );
      navigate(routes.dashBoard);
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const { mutate: socialGoogleLoginPostMutate, isLoading: socialGoogleIsLoading } = useMutation(socialLogin, {
    onSuccess: async (data) => {
      toast.success(data.message, TOAST_CONFIG);
      const sid = localStorage.getItem(SUB_ID_LOCAL_STORAGE_KEY);
      const query = new URLSearchParams(search);
      const referralCode = localStorage.getItem(REFERRAL_CODE_LOCAL_STORAGE_KEY);
      const userObj = {
        name: `${data.data.user.first_name} ${data.data.user.last_name}`,
        email: data.data.user.email,
        is_free_user: data.data.user.is_free_user,
        is_sub_user: data.data.user.is_sub_user,
      };
      if (query.get('cd') === '65bb76009a551920409b8733') userObj.referralCode = referralCode;
      if (sid) {
        await addSubscriptionMutation.mutate(
          { sid },
          {
            onSuccess: (subData) => {
              userObj.is_sub_user = subData.is_sub_user;
              localStorage.removeItem(SUB_ID_LOCAL_STORAGE_KEY);
              if (query.get('cd') === '65bb76009a551920409b8733')
                localStorage.removeItem(REFERRAL_CODE_LOCAL_STORAGE_KEY);
              // navigate(routes.dashBoard);
            },
            onError: (error) => {
              catchBlock(error);
              localStorage.removeItem(SUB_ID_LOCAL_STORAGE_KEY);
              if (query.get('cd') === '65bb76009a551920409b8733')
                localStorage.removeItem(REFERRAL_CODE_LOCAL_STORAGE_KEY);
              // navigate(routes.dashBoard);
            },
          },
        );
      }
      localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, data.data.token);
      localStorage.setItem(USER_DATA_LOCAL_STORAGE_KEY, JSON.stringify(userObj));
      localStorage.setItem(ACCESS_TOKEN_LOGIN_TIME_LOCAL_STORAGE_KEY, moment());
      localStorage.setItem(
        ACCESS_TOKEN_EXPIRATION_TIME_LOCAL_STORAGE_KEY,
        moment().add(ACCESS_TOKEN_LOGIN_EXPIRE_MINUTES_LOCAL_STORAGE_KEY, 'minutes'),
      );
      navigate(routes.dashBoard);
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      const headers = {
        Authorization: `Bearer ${codeResponse.access_token}`,
        Accept: 'application/json',
      };
      googleLoginApi(headers, codeResponse.access_token)
        .then((res) => {
          const requestValues = {
            email: res.email,
            first_name: res.family_name,
            last_name: res.given_name,
            social_login_type: 'Google',
            provider_id: res.id,
          };
          socialGoogleLoginPostMutate(requestValues);
        })
        .catch((err) => catchBlock(err));
    },
    onError: (error) => catchBlock(error),
  });

  const responseFailFacebook = (error) => {
    catchBlock(error);
  };

  const responseFacebook = (response) => {
    if (response.status === 'loginCancelled') {
      return false;
    }
    if (response.error) {
      catchBlock(response);
    }
    if (response.name) {
      const splitname = response.name.split(' ');
      const firstName = splitname[0] || '';
      const lastName = splitname[1] || '';
      const requestValues = {
        email: response.email,
        first_name: firstName,
        last_name: lastName,
        social_login_type: 'Facebook',
        provider_id: response.id,
      };
      socialLoginPostMutate(requestValues);
    }
    return true;
  };
  return (
    <div className="register_background register_height100vh">
      <PageTitle title="Register" />
      <div className="register_logo_div">
        <TextTitleLogo />
      </div>
      <div className="register_form_manager">
        <div className="register_width_container justify-content-center mx-auto text-center my-5 pt-3">
          <FormHeading>Create an account</FormHeading>
          <p className="text-white width_register_60 justify-content-center mx-auto mt-3 mb-4">
            One space for your brainstorming experiences.
          </p>
          <div className="register_formik_div px-4">
            <Formik
              initialValues={initVals}
              enableReinitialize
              validationSchema={SignUpValidation}
              onSubmit={RegisterPost}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="register_form_fields_div">
                    <Field
                      placeholder="Name"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      onBlur={handleBlur}
                      component={InputBox}
                    />
                    {errors.name && touched.name ? (
                      <div className="register_lightRed d-block justify-content-start mx-auto text-start">
                        {errors.name}
                      </div>
                    ) : null}
                    <Field
                      placeholder="Email"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                      component={InputBox}
                      disabled={initVals.email !== '' && !initVals.prefilled}
                    />
                    {errors.email && touched.email ? (
                      <div className="register_lightRed d-block  justify-content-center mx-auto text-start">
                        {errors.email}
                      </div>
                    ) : null}
                    <Field
                      placeholder="Password"
                      name="password"
                      onChange={handleChange}
                      value={values.password}
                      onBlur={handleBlur}
                      type="password"
                      component={InputBox}
                    />
                    {errors.password && touched.password ? (
                      <div className="register_lightRed d-block  justify-content-center mx-auto text-start">
                        {errors.password}
                      </div>
                    ) : null}
                  </div>
                  <Button disabled={isLoading}>{isLoading ? 'Loading...' : 'Register'}</Button>
                  <button
                    type="button"
                    disabled={socialGoogleIsLoading}
                    onClick={() => googleLogin()}
                    className="submitButtonwithGoogle"
                  >
                    <img src={Google} alt="" style={{ width: '2rem' }} />
                    {socialGoogleIsLoading ? 'Loading...' : 'Login with Google'}
                  </button>
                  <button type="button" disabled={socialIsLoading} className="facebookBackgroundButton">
                    <FaFacebookF style={{ color: 'white' }} />
                    {socialIsLoading ? (
                      <h6 className="login_facebook_loader">Loading...</h6>
                    ) : (
                      <FacebookLogin
                        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                        autoLoad={false}
                        fields="name,email,picture"
                        onSuccess={responseFacebook}
                        onFail={responseFailFacebook}
                        onProfileSuccess={responseFacebook}
                        size="metro"
                        tag="button"
                        className="faceBookButton"
                      />
                    )}
                  </button>
                  <button type="button" className="registerBtn mt-3" onClick={Login}>
                    Log In
                  </button>
                  <button
                    type="button"
                    className="registerBtn registerBtnMobile mt-3"
                    onClick={() => setMobileRestrictionModalClass('d-block')}
                  >
                    Log In
                  </button>
                  {/* <h6 className="text-white mt-3">
                    Already Have Account ?{' '}
                    <Link to="/login" className="register_lightRed logInLink">
                      Log In
                    </Link>
                  </h6> */}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <MobileRestrictionModal wrapperClass={mobileRestrictionModalClass} />
    </div>
  );
};

export default RegisterPage;
