import Api from './axios.config';

export const mindMampApi = async (data) => {
  const response = await Api.post('/mind-map', data);
  return response.data;
};

export const mindMampDelete = async (id) => {
  const response = await Api.delete('/mind-map', { data: { id } });
  return response.data;
};

export const mindMapGetAll = async (data) => {
  const searchKey = data.queryKey[1] || '';
  const company = data.queryKey[2] || '';
  const response = await Api.get(`mind-map?search=${searchKey}&company=${company}`);
  return response.data;
};

export const getMindMapApi = async (data) => {
  const response = await Api.get(`/mind-map/getById/${data}`);
  return response.data;
};

export const getMindMapExportApi = async (data) => {
  const response = await Api.get(`/mind-map/export?${new URLSearchParams(data).toString()}`);
  return response.data;
};

export const getMindMapResults = async (data) => {
  // const response = await Api.get(`/mind-map/script?${new URLSearchParams(data).toString()}`);
  const response = await Api.post(`/mind-map/script`, data);
  return response.data;
};
export const getMindMapResultsCombined = async (data) => {
  const response = await Api.get(`/mind-map/scriptCombined?${new URLSearchParams(data).toString()}`);
  return response.data;
};
export const getMindMapResultsExport = async (data) => {
  const response = await Api.post(`/mind-map/scriptExport`, data);
  return response.data;
};

export const updateWorkspaceApi = async (data) => {
  const response = await Api.put('mind-map', data);
  return response.data;
};

export const checkWorkspaceAccess = async (data) => {
  const workSpaceId = data.queryKey[1];
  const response = await Api.get(`/collabrator/check-access/${workSpaceId}`);
  return response.data;
};

export const GetComments = async (obj) => {
  const response = await Api.post('/mind-map/get-comment', obj?.queryKey[0]);
  return response.data;
};

export const AddComments = async (data) => {
  const response = await Api.post('/mind-map/post-comment', data);
  return response.data;
};

export const getAreas = async () => {
  const response = await Api.get('/mind-map/get_areas');
  return response.data;
};

export const getAttributes = async (data) => {
  const response = await Api.post('/mind-map/get_attributes', data);
  return response.data;
};

export const getWebsite = async (data) => {
  const response = await Api.post('/mind-map/get-website-scrape', data);
  return response.data;
};

export const getWebsiteResponse = async (data) => {
  const response = await Api.post('/new-response', data);
  return response.data;
};

export const getMindMampFileContentApi = async (data) => {
  const response = await Api.post('/mind-map/get-file-content', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const generateGroupNodesData = async (data) => {
  const response = await Api.post('/mind-map/generate-group-nodes', data);
  return response.data;
};

export const getMindmapDetails = async (data) => {
  const response = await Api.post(`/mind-map/get-details`, data);
  return response.data;
};

export const updateMindmapDetails = async (data) => {
  const response = await Api.post(`/mind-map/update-details`, data);
  return response.data;
};

export const getMindmapActionPlan = async (data) => {
  const response = await Api.post(`/mind-map/get-action-plan`, data);
  return response.data;
};
export const getMindmapEvaluate = async (data) => {
  const response = await Api.post(`/mind-map/get-evaluate`, data);
  return response.data;
};

export const getMindmapComments = async (data) => {
  const nodeData = data.queryKey[1];
  const response = await Api.post(`/mind-map/get-comment`, nodeData);
  return response.data;
};
