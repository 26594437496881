/* eslint-disable simple-import-sort/imports */
import React from 'react';
import { BsExclamationTriangleFill } from 'react-icons/bs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { routes } from 'constant';

import 'components/TopNavigationBar/MobileRestrictionModal.css';

const MobileRestrictionModal = ({ wrapperClass }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleRegister = () => {
    if (location.pathname === routes.register) {
      navigate(0);
    } else {
      navigate(routes.register);
    }
  };
  return (
    <div className={`mobile_restriction ${wrapperClass}`}>
      <div className="mobile_restriction_box">
        <BsExclamationTriangleFill className="exclamation-icon" size={50} />
        <h3>You can only use Ideon AI on desktop.</h3>
        <p>You can still start your sign up on mobile and continue your onboarding on desktop later.</p>
        <button type="button" className="open-btn" onClick={handleRegister}>
          Register
        </button>
      </div>
    </div>
  );
};

MobileRestrictionModal.defaultProps = {
  wrapperClass: '',
};

MobileRestrictionModal.propTypes = {
  wrapperClass: PropTypes.string,
};

export default MobileRestrictionModal;
