import Api from './axios.config';

export const getFormAttributes = async (data = {}) => {
  const response = await Api.post('/company-profile/get-form-attributes', data);
  return response.data;
};

export const getCompanyObject = async (data) => {
  const response = await Api.post('/company-profile/get-company-object', data);
  return response.data;
};

export const storeCompanyProfile = async (data) => {
  const response = await Api.post('/company-profile/store', data);
  return response.data;
};

export const getCompanyProfile = async (data = {}) => {
  const response = await Api.get('/company-profile', { params: data });
  return response.data;
};
export const updateDefaultCompanyProfileAPI = async (data = {}) => {
  const response = await Api.put('/company-profile/update-default', data);
  return response.data;
};

export const deleteCompanyProfile = async (id) => {
  const response = await Api.delete('/company-profile', { data: { id } });
  return response.data;
};

export const getCompanyProfileEditData = async (id) => {
  const response = await Api.get(`/company-profile/${id}/edit`, {});
  return response.data;
};

export const updateCompanyProfile = async ({ id, data }) => {
  const response = await Api.post(`/company-profile/update`, { ...data, company_id: id });
  return response.data;
};

export const createCompanyProfileCollaborator = async (data) => {
  const response = await Api.post('/company-profile/collaborator/create', data);
  return response.data;
};

export const getCompanyProfileCollaboratorData = async (id) => {
  const response = await Api.get(`/company-profile/${id}/collaborator`, {});
  return response.data;
};

export const deleteCompanyProfileCollaborator = async (id) => {
  const response = await Api.delete('/company-profile/collaborator', { data: { id } });
  return response.data;
};
