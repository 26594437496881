/* eslint-disable react/button-has-type */
/* eslint-disable import/order */
import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { AiFillApi, AiOutlinePlus } from 'react-icons/ai';
import Lottie from 'react-lottie';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { routes } from 'constant';
import moment from 'moment';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/order
import Swal from 'sweetalert2';
import { mindMampDelete, mindMapGetAll } from 'api/mindMamp';
import { getUserSubscription } from 'api/payment';
import { catchBlock } from 'utils';
import { TitleandSerchbar } from '..';
import ModalConfirmation from './Components/ModalConfirmation';
import animationLink from 'assets/link.json';
import animationTrash from 'assets/trash.json';
import user from 'assets/users.png';

import './style.css';

const NewWrokSpaceCard = ({ companyProfile }) => {
  const [modalShow, setModalShow] = useState(false);
  const [workcardId, setWorkcardId] = useState(-1);
  const [deleteWorkcardId, setDeleteWorkcardId] = useState(-1);
  const [counter, setCounter] = useState(0);
  const filterFormRef = useRef();
  const location = useLocation();

  const openModalConformation = () => {
    setModalShow(true);
  };

  const closeoModalConformation = () => {
    setModalShow(false);
    setWorkcardId(-1);
  };
  const [workspaceListData, setWorkspaceListData] = useState(null);
  const [redirectSub, setRedirectSub] = useState(null);
  const [remainingDays, setRemainingDays] = useState(null);
  const navigate = useNavigate();

  const HandleNavigate = () => {
    if (redirectSub === false && workspaceListData !== null) navigate(routes.mindMap);
    else navigate(routes.stripeEmbedDemo);
  };

  const {
    mutate: fetchWorkSpace,
    isFetching,
    refetch: refetchWorkspace,
  } = useQuery({
    queryKey: ['mindMapGetAll', filterFormRef?.current?.values?.marketing || '', companyProfile || ''],
    queryFn: mindMapGetAll,
    refetchOnMount: 'always',
    onSuccess: async (data) => {
      const length = data.data.length + data.shaedMindMap.length;
      const sharedWorkspaceUpdatedFlag = await Promise.all(
        data.shaedMindMap.map((shared) => {
          return {
            ...shared,
            isOwner: false,
            isDisabled: !!JSON.parse(shared.output)?.nodeDataArray || !shared.company_profile_id,
          };
        }),
      );
      const ownWorkspaceUpdatedFlag = await Promise.all(
        data.data.map((own) => {
          return {
            ...own,
            isOwner: true,
            isDisabled: !!JSON.parse(own.output)?.nodeDataArray || !own.company_profile_id,
          };
        }),
      );
      setWorkspaceListData([...ownWorkspaceUpdatedFlag, ...sharedWorkspaceUpdatedFlag]);
      setCounter(length);
    },
    onError: (err) => {
      catchBlock(err);
    },
  });

  const {
    mutate: fetchUserSub,
    isFetching: isFetchingUserSubscription,
    refetch: refetchUserSub,
  } = useQuery({
    queryKey: ['user-subscription-expire'],
    queryFn: getUserSubscription,
    refetchOnMount: 'always',
    onSuccess: (data) => {
      if (
        data.data &&
        data.data.status !== 'active' &&
        data.data.status !== 'free_trial' &&
        data.data.status !== 'trialing'
      ) {
        setRedirectSub(true);
        if (data.data.status === 'expired') setRemainingDays(0);
      } else {
        setRedirectSub(false);
        setRemainingDays(data?.data?.remaining_days || null);
      }
    },
    onError: (err) => {
      catchBlock(err);
    },
  });

  const { mutate: deleteWorkSpace, isLoading: deleteLoader } = useMutation(mindMampDelete, {
    onSuccess: (data, variables) => {
      const filteredResult = workspaceListData?.filter((item) => item._id !== variables);
      setWorkspaceListData(filteredResult);
      setCounter(filteredResult.length);
      // refetchUserSub();
      // refetchWorkspace();

      Swal.fire('Deleted!', 'Your workspace has been deleted.', 'success');
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const defaultOptions = {
    loop: true,
    autoplay: false,
    animationData: animationTrash,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const successAlert = (id) => {
    setDeleteWorkcardId(id);
    setTimeout(() => {
      setDeleteWorkcardId(-1);
    }, 950);
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteWorkSpace(id);
      }
    });
  };

  useEffect(() => {
    if (fetchUserSub) fetchUserSub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchWorkSpace) refetchWorkspace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyProfile]);

  return (
    <div>
      <TitleandSerchbar
        others={false}
        refetchWorkspace={refetchWorkspace}
        filterFormRef={filterFormRef}
        counter={counter}
      />
      {(isFetching || isFetchingUserSubscription) && (
        <div className="spinner-div">
          <Spinner animation="border" className="spiner" />
        </div>
      )}
      {!isFetching && !isFetchingUserSubscription && (
        <div className="newworkplace_container">
          <div className="cardDiv dashboard_workspace-card-wrapper">
            <div className="first-card dashboard_workspace-card">
              <div className="card-class">
                <h4 className="cardTitle">New Workspace</h4>
                <div className="plusIconDiv">
                  <AiOutlinePlus
                    onClick={HandleNavigate}
                    color="#fff"
                    size="60px"
                    fontWeight="bold"
                    className="plusIcon"
                  />
                </div>
              </div>
            </div>
            {workspaceListData?.map((workCard) => (
              <div
                className={`dataCard dashboard_workspace-card ${workCard?.isDisabled && 'card-disabled'}`}
                key={workCard._id}
              >
                {workCard?.isDisabled && <p className="disabled-lable">Workspace no longer supported.</p>}

                <div className="child-card-div">
                  <div className="child-card-div-top">
                    <h5 className="data-card-heading">Workspace Name</h5>
                    <h4 className="data-card-title">{workCard.title}</h4>
                  </div>

                  <div className="child-card-div-bottom">
                    <div
                      className={workCard.title.length > 39 ? 'data-card-img-div-without-marging' : 'data-card-img-div'}
                    >
                      <img src={user} alt="users" className="data-card-img" />
                      <span className="data-card-span">
                        {workCard.collaborator_count ? `${workCard.collaborator_count + 1} Users` : 'Only Me'}
                      </span>
                    </div>

                    <div className="data-card-buttons-div">
                      <div className="delete-button-div">
                        {workCard.isOwner === true && (
                          <>
                            <button
                              type="button"
                              className="lottie-btns"
                              onClick={() => {
                                setWorkcardId(workCard);
                                openModalConformation();
                              }}
                              disabled={!!workCard?.isDisabled}
                            >
                              <Lottie
                                options={{
                                  loop: false,
                                  autoplay: false,
                                  animationData: animationLink,
                                }}
                                height={25}
                                width={25}
                                isStopped={workcardId !== workCard._id}
                              />
                            </button>

                            <button
                              type="button"
                              disabled={deleteLoader}
                              className="delete_button_background"
                              onClick={() => {
                                successAlert(workCard._id);
                              }}
                            >
                              {deleteLoader ? (
                                'Loading...'
                              ) : (
                                <Lottie
                                  options={{
                                    loop: true,
                                    autoplay: false,
                                    animationData: animationTrash,
                                  }}
                                  // options={defaultOptions}
                                  height={25}
                                  width={25}
                                  isStopped={deleteWorkcardId !== workCard._id}
                                />
                              )}
                            </button>
                          </>
                        )}
                      </div>

                      {workCard?.isDisabled ? (
                        <button className="open-btn" disabled>
                          Open
                        </button>
                      ) : (
                        <Link className="open-btn" to={routes.workspaceEdit.replace(':id', workCard._id)}>
                          Open
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {modalShow === true && (
        <ModalConfirmation
          refetchWorkspace={refetchWorkspace}
          closeoModalConformation={closeoModalConformation}
          workSpaceData={workcardId}
        />
      )}
    </div>
  );
};

NewWrokSpaceCard.defaultProps = {
  companyProfile: null,
};

NewWrokSpaceCard.propTypes = {
  companyProfile: PropTypes.string,
};

export default NewWrokSpaceCard;
