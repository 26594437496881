/* eslint-disable import/no-unresolved */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
// import Carousel from 'react-multi-carousel';
import Slider from 'react-slick';
import { Autoplay } from 'swiper/modules';
// import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import logo3 from 'assets/logos/logo-corebiz-global.png';
import logo6 from 'assets/logos/m_booth_logo-Photoroom.png';
import logo7 from 'assets/logos/mccann-paris-1556_1674827981-Photoroom.png';
import logo9 from 'assets/logos/Otherway-logo.png';
import logo10 from 'assets/logos/Revolut-Logo.png';
import logo11 from 'assets/logos/slove-logo-Photoroom.png';
import logo14 from 'assets/logos/wone_altavia_logo.png';

import 'swiper/css';
import './style.css';
// eslint-disable-next-line import/no-extraneous-dependencies
// import 'react-multi-carousel/lib/styles.css';

const LogoSlider = () => {
  // const settings = {
  //   dots: false,
  //   arrows: false,
  //   infinite: true,
  //   // slidesToShow: 9,
  //   variableWidth: true,
  //   // slidesToScroll: 1,
  //   autoplay: true,
  //   speed: 3000,
  //   autoplaySpeed: 3000,
  //   pauseOnHover: false,
  //   cssEase: 'linear',
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         // slidesToShow: 5,
  //         // slidesToScroll: 3,
  //         // infinite: true,
  //         // dots: true,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         // slidesToShow: 4,
  //         // slidesToScroll: 2,
  //         // initialSlide: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         // slidesToShow: 3,
  //         // slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  return (
    <div className="steps_section logo-slider">
      <div className="slider-container">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {/* <Slider {...settings}>
          <div className="img_container">
            <img src="assets/logos/thumbnail-1-1.png" alt="thumbnail-1-1.png" />
          </div>
          <div className="img_container">
            <img src={logo3} alt="logo3" />
          </div>
          <div className="img_container">
            <img src="assets/logos/mischief_usa_logo-Photoroom.png" alt="mischief_usa_logo-Photoroom.png" />
          </div>
          <div className="img_container">
            <img src={logo6} alt="logo6" />
          </div>
          <div className="img_container">
            <img src="assets/logos/Supercell_Logo.png" alt="Supercell_Logo.png" />
          </div>
          <div className="img_container">
            <img src="assets/logos/Inphluent_2.png" alt="Inphluent_2.png" />
          </div>
          <div className="img_container">
            <img src="assets/logos/Intuit-Logo-Photoroom.png" alt="Intuit-Logo-Photoroom.png" />
          </div>
          <div className="img_container">
            <img
              src="assets/logos/logo-duunitori-rgb-horisontal-inverse.png"
              alt="logo-duunitori-rgb-horisontal-inverse.png"
            />
          </div>
          <div className="img_container">
            <img src="assets/logos/logo-white.png" alt="logo-white.png" />
          </div>
          <div className="img_container">
            <img src={logo7} alt="logo7" />
          </div>
          <div className="img_container">
            <img src={logo9} alt="logo9" />
          </div>
          <div className="img_container">
            <img src={logo10} alt="logo10" />
          </div>
          <div className="img_container">
            <img src={logo11} alt="logo11" />
          </div>
          <div className="img_container">
            <img src={logo14} alt="logo14" />
          </div>
        </Slider> */}
        {/* <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlay
          autoPlaySpeed={1000}
          // centerMode
          // className=""
          // containerClass="container-with-dots"
          customTransition="all 1s linear"
          // dotListClass=""
          // focusOnSelect={false}
          infinite
          // itemClass=""
          // renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 5,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
          }}
          shouldResetAutoplay
          showDots={false}
          slidesToSlide={1}
          // transitionDuration={2000}
        >
          <img
            alt="Fixing CSS load order/style.chunk.css incorrect in Nextjs"
            src="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
          />
          <img
            alt="React Carousel with Server Side Rendering Support – Part 2"
            src="https://images.unsplash.com/photo-1549396535-c11d5c55b9df?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
          />
          <img
            alt="Appending currency sign to a purchase form in your e-commerce site using plain JavaScript."
            src="https://images.unsplash.com/photo-1550133730-695473e544be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
          />
          <img
            alt="React Carousel with Server Side Rendering Support – Part 2"
            src="https://images.unsplash.com/photo-1550167164-1b67c2be3973?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
          />
          <img
            alt="React Carousel with Server Side Rendering Support – Part 2"
            src="https://images.unsplash.com/photo-1550338861-b7cfeaf8ffd8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
          />
        </Carousel> */}
        <Swiper
          // spaceBetween={30}
          // centeredSlides
          slidesPerView={2.5}
          loop
          spaceBetween={30}
          speed={3000}
          autoplay={{
            delay: 0,
          }}
          modules={[Autoplay]}
          breakpoints={{
            767: {
              slidesPerView: 2.5,
            },
            1024: {
              slidesPerView: 3,
            },
            1400: {
              slidesPerView: 4,
              loop: true,
              spaceBetween: 30,
              speed: 3000,
              autoplay: {
                delay: 0,
              },
            },
          }}
        >
          <SwiperSlide>
            <img src="assets/logor/1.png" alt="thumbnail-1-1.png" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="assets/logor/2.png" alt="thumbnail-1-1.png" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="assets/logor/3.png" alt="mischief_usa_logo-Photoroom.png" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="assets/logor/4.png" alt="logo6" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="assets/logor/5.png" alt="Supercell_Logo.png" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="assets/logor/6.png" alt="Inphluent_2.png" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="assets/logor/7.png" alt="Intuit-Logo-Photoroom.png" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="assets/logor/8.png" alt="logo-duunitori-rgb-horisontal-inverse.png" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="assets/logor/9.png" alt="logo-white.png" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="assets/logor/10.png" alt="logo7" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="assets/logor/11.png" alt="logo9" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="assets/logor/12.png" alt="logo10" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="assets/logor/13.png" alt="logo11" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="assets/logor/14.png" alt="logo14" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default LogoSlider;
