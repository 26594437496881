import React from 'react';
import { Link } from 'react-router-dom';
import img1 from 'assets/home-new/advantage/image1.png';
import img2 from 'assets/home-new/advantage/image2.png';
import img3 from 'assets/home-new/advantage/image3.png';
import img4 from 'assets/home-new/advantage/image4.png';
import img5 from 'assets/home-new/advantage/image5.png';
import img6 from 'assets/home-new/advantage/image6.png';
import img7 from 'assets/home-new/advantage/image7.png';

import 'bootstrap/dist/css/bootstrap.css';
import './style.css';

const Advantage = () => {
  return (
    <div className="container advantage_wrapper">
      <h2 className="home_advantage-title">Ideon AI gives you competitive advantage</h2>
      <div className="home_advantage-block-wrapper">
        <div className="home_advantage-block row align-items-center flex-column-reverse flex-lg-row gap-5 gap-lg-0">
          <div className="col-lg-6">
            <img src={img1} alt="Deliver lightning fast.Reduce costs. Increase quality." />
          </div>
          <div className="col-lg-6">
            <div className="content_wrapper">
              <h3 className="block_title">
                Deliver lightning fast.
                <br />
                Reduce costs. Increase quality.
              </h3>
              <p className="block_text text-white">
                Explain your objective. Generate personalised ideas, evaluations, analysis and action plans in one
                click.
              </p>
            </div>
          </div>
        </div>
        <div className="home_advantage-block row align-items-center gap-5 gap-lg-0">
          <div className="col-lg-6">
            <div className="content_wrapper">
              <h3 className="block_title">Build client trust.</h3>
              <p className="block_text text-white">
                Data-backed recommendations enhance credibility. Ideon automatically integrates client data, providing
                tailored insights for informed decisions.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <img src={img2} alt="Deliver lightning fast.Reduce costs. Increase quality." className="" />
          </div>
        </div>
        <div className="home_advantage-block row align-items-center flex-column-reverse flex-lg-row gap-5 gap-lg-0">
          <div className="col-lg-6">
            <img src={img3} alt="Deliver lightning fast.Reduce costs. Increase quality." />
          </div>
          <div className="col-lg-6">
            <div className="content_wrapper">
              <h3 className="block_title">Use AI easier than ever.</h3>
              <p className="block_text text-white">
                Minimised manual input. One time setup simply by providing a website. We analyse and extract all
                business information. You can validate and edit it anytime.
              </p>
            </div>
          </div>
        </div>
        <div className="home_advantage-block row align-items-center gap-5 gap-lg-0">
          <div className="col-lg-6">
            <div className="content_wrapper">
              <h3 className="block_title">Use actionable strategy templates.</h3>
              <p className="block_text text-white">
                Strategy frameworks based on industry best practices. Customisable and powered by AI.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <img src={img4} alt="Deliver lightning fast.Reduce costs. Increase quality." />
          </div>
        </div>
        <div className="home_advantage-block row align-items-center flex-column-reverse flex-lg-row gap-5 gap-lg-0">
          <div className="col-lg-6">
            <img src={img5} alt="Deliver lightning fast.Reduce costs. Increase quality." />
          </div>
          <div className="col-lg-6">
            <div className="content_wrapper">
              <h3 className="block_title">Boost creativity.</h3>
              <p className="block_text text-white">
                Mind mapping and AI increase your creativity by up to 40% (
                <Link
                  title="2021 Mind Mapping Software Trends Survey"
                  target="_blank"
                  to="/2021-mms-trends-survey-report.pdf"
                  className="text-white text-decoration-none"
                >
                  backed by a study
                </Link>
                ). Broaden your perspective. Visualise connections. Unlock new insights.
              </p>
            </div>
          </div>
        </div>
        <div className="home_advantage-block row align-items-center gap-5 gap-lg-0">
          <div className="col-lg-6">
            <div className="content_wrapper">
              <h3 className="block_title">Control your work.</h3>
              <p className="block_text text-white">
                Ideon is created as an AI-powered assistance platform. Helping you in every step of your work. It is not
                aimed to replace people but to empower them. You can edit all data anytime.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <img src={img6} alt="Deliver lightning fast.Reduce costs. Increase quality." className="" />
          </div>
        </div>
        <div className="home_advantage-block row align-items-center flex-column-reverse flex-lg-row gap-5 gap-lg-0">
          <div className="col-lg-6">
            <img src={img7} alt="Deliver lightning fast.Reduce costs. Increase quality." className="" />
          </div>
          <div className="col-lg-6">
            <div className="content_wrapper">
              <h3 className="block_title">Collaborate and share.</h3>
              <p className="block_text text-white">
                Work with your team in real-time directly in the workspace.
                <br />
                Generate one click strategy reports and share with clients.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advantage;
